import axios from 'axios';

const sprintf = require('sprintf-js').sprintf;

export default class EntriesSettingsClient {
  constructor(type) {
    this.type = type;
  }

  async configureSetting({pathParameters, data} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_SETTINGS_V1_BASE_URL}/${this.type}/%(entryId)s/%(settingType)s`;
    return axios({url: `${sprintf(url, pathParameters)}`, method: 'post', data: data});
  }

  async getSetting({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_SETTINGS_V1_BASE_URL}/${this.type}/%(entryId)s/%(settingType)s`;
    return axios({url: `${sprintf(url, pathParameters)}`, method: 'get'});
  }
}
